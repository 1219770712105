/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUser } from '../models/AuthUser';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { Partner } from '../models/Partner';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
  /**
   * @returns AuthUser Retrieve a list of users
   * @throws ApiError
   */
  public static usersServiceGetUsers(): CancelablePromise<Array<AuthUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns AuthUser Ok
   * @throws ApiError
   */
  public static usersServiceCreateUser(
    requestBody: CreateUserRequest,
  ): CancelablePromise<AuthUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Malformed query`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `The partner referenced was not found`,
      },
    });
  }
  /**
   * @param id
   * @returns AuthUser Retrieve a user by id
   * @throws ApiError
   */
  public static usersServiceGetUser(
    id: string,
  ): CancelablePromise<AuthUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns AuthUser Retrieve a user by id
   * @throws ApiError
   */
  public static usersServiceUpdateUser(
    id: string,
    requestBody: AuthUser,
  ): CancelablePromise<AuthUser> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param id
   * @returns any Remove User
   * @throws ApiError
   */
  public static usersServiceDeleteUser(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns any Update user claims
   * @throws ApiError
   */
  public static usersServiceSetUserRole(
    id: string,
    requestBody: Record<string, any>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}/claims',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param id
   * @returns Partner Retrieve the partner associated with this identity
   * @throws ApiError
   */
  public static usersServiceGetPartner(
    id: string,
  ): CancelablePromise<Partner> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}/partner',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns string Ok
   * @throws ApiError
   */
  public static usersServiceCreateAllAccounts(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/createAllPartners',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
