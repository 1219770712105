import { LocalPrintshop } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  BasicCell,
  colorMap,
  mapShiftColor,
  PointsCell,
  SplitCell,
  TotalsCell,
} from 'components/atoms/CompReportCells';
import { Loading } from 'components/atoms/Loading';
import { PrintedPage } from 'components/atoms/PrintedPage';
import { PrintOnly } from 'components/atoms/PrintOnly';
import { MainCard } from 'components/molecules/MainCard';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { format, getDaysInMonth, startOfYear } from 'date-fns';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { ReportsService } from 'openapi';
import { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { SchedulePeriod, StorageItems } from 'util/enum';
import { getStorageDate, setStorageDate } from 'util/storage';
import { nbsp } from 'util/unicode';

const ShadingLegend: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid container px={4}>
      {colorMap(theme).map((map) => (
        <Grid
          item
          key={`${map.shift}-weekend`}
          xs={3}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <>
            <Box
              sx={{
                backgroundColor: mapShiftColor(theme, map.shift, true),
                display: 'inline-block',
              }}
              width={theme.spacing(4)}
              mr={2}>
              {nbsp}
            </Box>
            {map.shift} (Weekend)
          </>
        </Grid>
      ))}
      {colorMap(theme).map((map) => (
        <Grid item key={`${map.shift}-weekend`} xs={3}>
          <>
            <Box
              sx={{
                backgroundColor: mapShiftColor(theme, map.shift, false),
                display: 'inline-block',
              }}
              width={theme.spacing(4)}
              mr={2}>
              {nbsp}
            </Box>
            {map.shift}
          </>
        </Grid>
      ))}
    </Grid>
  );
};

export const CompReport: React.FC = () => {
  const [reportDate, setReportDate] = useState<Date>(
    getStorageDate(StorageItems.DisplayDate, new Date()),
  );
  const reportYear = reportDate.getFullYear();
  const reportMonth = reportDate.getMonth() + 1;
  const reportQuarter = Math.floor((reportMonth - 1) / 3 + 1);

  const theme = useTheme();
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const { data: compReport, isLoading } = useQuery(
    ['compReport', reportYear],
    async () => ReportsService.reportsServiceGetCompPointsReport(reportYear),
  );

  const { data: partners } = usePartnersQuery();

  const setDateWithSave = (date: Date) => {
    setStorageDate(StorageItems.DisplayDate, date);
    setReportDate(date);
  };

  const formatCuts = (cuts: number, x: number) => `C${cuts} X${x}`;

  const { monthTotals, quarterTotals, yearTotals } = useMemo(() => {
    if (!compReport) {
      return {
        monthTotals: null,
        quarterTotals: null,
        yearTotals: null,
      };
    }

    const zeroTotal = {
      points: 0,
      cuts: 0,
      x: 0,
    };

    const monthTotals =
      compReport.totals.find((total) => total.month === reportMonth) ??
      zeroTotal;
    const quarterTotals = compReport.totals
      .filter(
        (total) =>
          total.quarter === reportQuarter && total.month <= reportMonth,
      )
      .reduce(
        (accum, total) => ({
          points: accum.points + total.points,
          cuts: accum.cuts + total.cuts,
          x: accum.x + total.x,
        }),
        zeroTotal,
      );
    const yearTotals = compReport.totals
      .filter((total) => total.month <= reportMonth)
      .reduce(
        (accum, total) => ({
          points: accum.points + total.points,
          cuts: accum.cuts + total.cuts,
          x: accum.x + total.x,
        }),
        zeroTotal,
      );

    return {
      monthTotals,
      quarterTotals,
      yearTotals,
    };
  }, [compReport, reportMonth, reportQuarter]);

  const daysInMonth = Array(getDaysInMonth(reportDate))
    .fill(0)
    .map((_, i) => i + 1);

  const widthWithDays = Math.max(4 * 2 + daysInMonth.length * 4, 80);
  const widthSpec = theme.spacing(widthWithDays);

  return (
    <Grid item xs={12}>
      <Typography variant="h1">Compensation Report</Typography>
      <Box m={4} />
      <MainCard sx={{ minWidth: widthSpec }}>
        <Grid container justifyContent="center">
          <Grid item>
            <Box minWidth={100} />
          </Grid>
          <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
            <PeriodHeader
              period={SchedulePeriod.Monthly}
              startDate={reportDate}
              onSelectDate={setDateWithSave}
            />
          </Grid>
          <Grid item width={100} display="flex" justifyContent="right">
            <Button onClick={handlePrint}>
              <LocalPrintshop />
            </Button>
          </Grid>
        </Grid>

        <div ref={printRef}>
          {compReport ? (
            <PrintedPage>
              <PrintOnly>
                <Typography variant="h2" textAlign="center" pb={3}>
                  {format(reportDate, 'MMMM y')}
                </Typography>
              </PrintOnly>
              <Grid item display="flex" alignItems="top">
                <BasicCell width={2} />
                {daysInMonth.map((num) => (
                  <BasicCell key={num}>{num}</BasicCell>
                ))}
                <BasicCell width={2}>{format(reportDate, 'MMM')}</BasicCell>
                <BasicCell width={2}>{`Q${reportQuarter}`}</BasicCell>
                <BasicCell width={2}>{reportYear}</BasicCell>
              </Grid>
              {compReport.partners
                .sort(
                  (a, b) =>
                    a.partnerNickname?.localeCompare(
                      b?.partnerNickname ?? '',
                    ) ?? 0,
                )
                .map((partner) => {
                  const month = partner?.months?.find(
                    (month) => month.month === reportMonth,
                  );
                  const partnerDate = partners?.find(
                    (p) => p.id === partner.partnerId,
                  )?.partnerDate;
                  const employeeShading: SxProps | undefined =
                    partnerDate &&
                    new Date(partnerDate) >= startOfYear(reportDate)
                      ? ({
                          backgroundColor: theme.palette.grey[200],
                        } as SxProps)
                      : undefined;
                  return (
                    <Grid
                      item
                      height={theme.spacing(5)}
                      key={partner.partnerId}
                      display="flex"
                      alignItems="top">
                      <BasicCell width={2}>{partner.partnerNickname}</BasicCell>
                      {daysInMonth.map((day) => {
                        const found = month?.days?.find(
                          (d) =>
                            day === parseInt(d.date?.substring(8, 10) ?? ''),
                        );
                        return found ? (
                          <PointsCell
                            key={`${partner.partnerId}-${day}`}
                            day={found}
                          />
                        ) : (
                          <SplitCell key={`${partner.partnerId}-${day}`} />
                        );
                      })}
                      <SplitCell
                        wide
                        points={month?.monthTotals.points ?? 0}
                        annotation={formatCuts(
                          month?.monthTotals.cuts ?? 0,
                          month?.monthTotals.x ?? 0,
                        )}
                        sx={{ ...employeeShading }}
                      />
                      <SplitCell
                        wide
                        points={month?.quarterTotals.points ?? 0}
                        annotation={formatCuts(
                          month?.quarterTotals.cuts ?? 0,
                          month?.quarterTotals.x ?? 0,
                        )}
                        sx={{ ...employeeShading }}
                      />
                      <SplitCell
                        wide
                        points={month?.annualTotals.points ?? 0}
                        annotation={formatCuts(
                          month?.annualTotals.cuts ?? 0,
                          month?.annualTotals.x ?? 0,
                        )}
                        sx={{ ...employeeShading }}
                      />
                    </Grid>
                  );
                })}
              <Grid
                item
                display="flex"
                alignItems="top"
                justifyContent="center"
                py={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: theme.spacing(20),
                    height: theme.spacing(10),
                  }}>
                  <Typography fontSize="1.8rem">Totals</Typography>
                </Box>

                <TotalsCell
                  period={format(reportDate, 'MMM')}
                  points={monthTotals?.points}
                  annotation={formatCuts(
                    monthTotals?.cuts ?? 0,
                    monthTotals?.x ?? 0,
                  )}
                />
                <TotalsCell
                  period={`Q${reportQuarter}`}
                  points={quarterTotals?.points}
                  annotation={formatCuts(
                    quarterTotals?.cuts ?? 0,
                    quarterTotals?.x ?? 0,
                  )}
                />
                <TotalsCell
                  period={reportYear.toString()}
                  points={yearTotals?.points}
                  annotation={formatCuts(
                    yearTotals?.cuts ?? 0,
                    yearTotals?.x ?? 0,
                  )}
                />
              </Grid>
              <ShadingLegend />
            </PrintedPage>
          ) : isLoading ? (
            <Loading />
          ) : (
            'Compensation Report not available.'
          )}
        </div>
      </MainCard>
      <Box m={4} />
    </Grid>
  );
};
