/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompPointsReport } from '../models/CompPointsReport';
import type { ShiftsReportConfig } from '../models/ShiftsReportConfig';
import type { ShiftsSummaryReport } from '../models/ShiftsSummaryReport';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReportsService {
  /**
   * @param year
   * @returns CompPointsReport Retrieve comp points report
   * @throws ApiError
   */
  public static reportsServiceGetCompPointsReport(
    year: number,
  ): CancelablePromise<CompPointsReport> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reports/comp-points/{year}',
      path: {
        'year': year,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @returns ShiftsReportConfig Retrieve shift assignments report config
   * @throws ApiError
   */
  public static reportsServiceGetShiftTotalsConfig(): CancelablePromise<ShiftsReportConfig> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reports/shifts/config',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns ShiftsReportConfig Update shift assignments report config
   * @throws ApiError
   */
  public static reportsServiceUpdateShiftTotalsConfig(
    requestBody: ShiftsReportConfig,
  ): CancelablePromise<ShiftsReportConfig> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/reports/shifts/config',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param year
   * @returns ShiftsSummaryReport Retrieve shift assignments report
   * @throws ApiError
   */
  public static reportsServiceGetShiftTotalsReport(
    year: number,
  ): CancelablePromise<ShiftsSummaryReport> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reports/shifts/{year}',
      path: {
        'year': year,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }
}
