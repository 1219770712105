import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  format,
} from 'date-fns';
import { useReactToPrint } from 'react-to-print';

import { MainCard } from 'components/molecules/MainCard';
import { usePartnerScheduleQuery } from 'hooks/usePartnerScheduleQuery';
import { useAuth } from 'providers/AuthProvider';
import { Loading } from 'components/atoms/Loading';
import { DataCell, RowHeaderCell } from 'components/atoms/ScheduleCells';
import { dateToString, monthSpan } from 'util/date';
import { useHolidaysQuery } from 'hooks/useHolidaysQuery';
import { PrintedPage } from 'components/atoms/PrintedPage';
import {
  Close,
  ContentCopy,
  IosShare,
  LocalPrintshop,
} from '@mui/icons-material';
import { PrintOnly } from 'components/atoms/PrintOnly';
import { usePublishDateQuery } from 'hooks/usePublishDateQuery';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { SchedulePeriod, StorageItems } from 'util/enum';
import { ScheduleService } from 'openapi';
import { useDownloadQuery } from 'hooks/useDownload';
import { DownloadButton } from 'components/atoms/DownloadButton';
import { useModal } from 'react-modal-hook';
import { getCalendarDownloadRoute } from 'util/routes';
import { ScreenOnly } from 'components/atoms/ScreenOnly';
import { getStorageDate, setStorageDate } from 'util/storage';

const ShiftCell: React.FC<{ date: string; shift: string }> = ({
  date,
  shift,
}) => {
  const theme = useTheme();
  const holidayYear = parseInt(date.substring(0, 4));
  const { data: holidays } = useHolidaysQuery(holidayYear);

  const holiday = holidays?.find((x) => x.date === date) ?? null;
  const holidayTooltip = holiday ? holiday.name : undefined;

  const pastCellBg = holiday
    ? { backgroundColor: theme.palette.yellow.light }
    : undefined;
  return (
    <DataCell sx={pastCellBg} title={holidayTooltip}>
      <Typography variant="body1">{shift}</Typography>
    </DataCell>
  );
};

export const PartnerSchedule: React.FC = () => {
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const { profile } = useAuth();

  const [date, setDate] = useState<Date>(
    getStorageDate(StorageItems.DisplayDate, new Date()),
  );
  const setDateWithSave = (date: Date) => {
    setStorageDate(StorageItems.DisplayDate, date);
    setDate(date);
  };

  const icalUrl =
    profile?.initials && profile?.token
      ? getCalendarDownloadRoute(profile?.initials, profile?.token)
      : 'Unavailable';

  const [monthStart, monthEnd] = monthSpan(date);

  const fromDate = dateToString(monthStart);
  const toDate = dateToString(monthEnd);
  const { isLoading, data: schedule } = usePartnerScheduleQuery(
    profile?.id, // fix this later
    fromDate,
    toDate,
  );

  const profileId = profile?.id;
  const { data, download } = useDownloadQuery(
    ['partnerDownload', profileId],
    () =>
      ScheduleService.scheduleServiceDownloadPartnerSchedule(
        profile?.initials ?? '',
        profile?.token ?? '',
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const [showExportModal, hideExportModal] = useModal(
    () => (
      <Dialog open={true} fullWidth maxWidth="sm" onClose={hideExportModal}>
        <Grid container justifyContent="right">
          <Button onClick={hideExportModal}>
            <Close />
          </Button>
        </Grid>
        <Typography variant="h2" textAlign="center">
          Export Assignment Calendar
        </Typography>
        <Grid container p={4}>
          <Grid item xs={12} pb={1}>
            <Typography variant="body1">
              Option 1: Subscribe to calendar
            </Typography>
          </Grid>
          <Grid item px={4} xs={12}>
            <OutlinedInput
              disabled
              value={icalUrl}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    title="copy"
                    color="primary"
                    onClick={() => navigator.clipboard.writeText(icalUrl)}>
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item py={4} />
          <Grid item xs={12} pb={1}>
            <Typography variant="body1">
              Option 2: Download iCal (.ics) file
            </Typography>
          </Grid>
          <Grid item px={4} xs={12}>
            <DownloadButton
              filename={'iwaa.ics'}
              caption="Download"
              data={data}
              download={download}
            />
          </Grid>
        </Grid>
      </Dialog>
    ),
    [data, download],
  );

  const { isLoading: isPublishedLoading, data: publishDate } =
    usePublishDateQuery(monthEnd);

  if (!profile) {
    return (
      <MainCard>No individual schedule is associated with this login.</MainCard>
    );
  }

  const publishStatus = publishDate?.date
    ? `Last published: ${dateToString(new Date(publishDate.date))}`
    : 'This schedule has not yet been published';

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Partner Schedule
      </Typography>
      <Box m={4} />
      <MainCard sx={{ minWidth: '680px' }}>
        <div ref={printRef}>
          <ScreenOnly>
            <Grid container justifyContent="center">
              <Grid item>
                <Box minWidth={200} />
              </Grid>
              <Grid
                item
                alignSelf="center"
                marginRight="auto"
                marginLeft="auto">
                <PeriodHeader
                  period={SchedulePeriod.Monthly}
                  startDate={date}
                  onSelectDate={setDateWithSave}
                />
              </Grid>
              <Grid item width={200} display="flex" justifyContent="right">
                <Button onClick={showExportModal}>
                  <IosShare />
                </Button>
                <Button onClick={handlePrint}>
                  <LocalPrintshop />
                </Button>
              </Grid>
            </Grid>
            {isPublishedLoading ? (
              <Loading />
            ) : (
              <Typography variant="subtitle1" textAlign="center">
                {publishStatus}
              </Typography>
            )}
          </ScreenOnly>
          <PrintOnly>
            <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
              <PeriodHeader
                period={SchedulePeriod.Monthly}
                startDate={date}
                onSelectDate={setDateWithSave}
              />
              <Typography variant="subtitle1" textAlign="center">
                {publishStatus}
              </Typography>
            </Grid>
          </PrintOnly>
          <Box pb={3} />
          {isLoading ? (
            <Loading />
          ) : (
            <PrintedPage>
              <Grid
                container
                columns={9}
                textAlign="center"
                justifyContent="center"
                pb={2}>
                <RowHeaderCell />
                {eachDayOfInterval({
                  start: monthStart,
                  end: addDays(monthStart, 6),
                }).map((date) => {
                  const weekday = format(date, 'eeeee');
                  return (
                    <Grid item xs={1} mb={2} key={dateToString(date)}>
                      <Typography variant="h3">{weekday}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              {eachWeekOfInterval(
                { start: monthStart, end: monthEnd },
                { weekStartsOn: 1 },
              ).map((monday) => (
                <Grid key={dateToString(monday)} container columns={9}>
                  <RowHeaderCell>
                    {format(monday, 'MMM d')} -{' '}
                    {format(addDays(monday, 6), 'MMM d')}
                  </RowHeaderCell>
                  {eachDayOfInterval({
                    start: monday,
                    end: addDays(monday, 6),
                  }).map((date) => {
                    const dateString = dateToString(date);
                    const shiftString = schedule
                      ? schedule[dateToString(date)]
                      : '';
                    return (
                      <ShiftCell
                        key={dateString}
                        date={dateString}
                        shift={shiftString}
                      />
                    );
                  })}
                </Grid>
              ))}
            </PrintedPage>
          )}
        </div>
      </MainCard>
      <Box m={4} />
    </Grid>
  );
};
