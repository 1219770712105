/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateUserRequest = {
  userType: CreateUserRequest.userType;
  partnerId?: number;
  email?: string;
  displayName?: string;
};
export namespace CreateUserRequest {
  export enum userType {
    PARTNER = 'partner',
    NON_PARTNER = 'non-partner',
    HOSPITAL = 'hospital',
  }
}

