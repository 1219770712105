/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentLink } from '../models/DocumentLink';
import type { DocumentPath } from '../models/DocumentPath';
import type { DocumentRef } from '../models/DocumentRef';
import type { NewId } from '../models/NewId';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DocumentsService {
  /**
   * @returns DocumentRef Retrieve a list of documents
   * @throws ApiError
   */
  public static documentsServiceGetDocuments(): CancelablePromise<Array<DocumentRef>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns string Delete a document
   * @throws ApiError
   */
  public static documentsServiceDeleteDocument(
    requestBody: DocumentPath,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @returns any Retrieve document metadata
   * @throws ApiError
   */
  public static documentsServiceGetDocumentMetadata(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/metadata',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns binary Download a document
   * @throws ApiError
   */
  public static documentsServiceDownloadDocument(
    requestBody: DocumentPath,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents/download',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param formData
   * @returns string Upload a document
   * @throws ApiError
   */
  public static documentsServiceUploadDocument(
    formData: {
      doc: Blob;
      dirName: string;
    },
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents/upload',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @returns DocumentLink Retrieve a list of document links
   * @throws ApiError
   */
  public static documentsServiceGetDocumentLinks(): CancelablePromise<Array<DocumentLink>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/links',
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns NewId Create a document link
   * @throws ApiError
   */
  public static documentsServiceCreateDocumentLink(
    requestBody: DocumentLink,
  ): CancelablePromise<NewId> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/documents/links',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param id
   * @returns DocumentLink Retrieve a document link
   * @throws ApiError
   */
  public static documentsServiceGetDocumentLink(
    id: number,
  ): CancelablePromise<DocumentLink> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/documents/links/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns DocumentLink Update a document link
   * @throws ApiError
   */
  public static documentsServiceUpdateDocumentLink(
    id: number,
    requestBody: DocumentLink,
  ): CancelablePromise<DocumentLink> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/documents/links/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static documentsServiceDeleteDocumentLink(
    id: number,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/documents/links/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
}
