/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewId } from '../models/NewId';
import type { Partner } from '../models/Partner';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PartnersService {
  /**
   * @returns Partner Retrieve a list of partners
   * @throws ApiError
   */
  public static partnersServiceGetPartners(): CancelablePromise<Array<Partner>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/partners',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns NewId Create a partner
   * @throws ApiError
   */
  public static partnersServiceCreatePartner(
    requestBody: Partner,
  ): CancelablePromise<NewId> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/partners',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static partnersServiceRemovePartner(
    id: number,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/partners/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @param id
   * @returns Partner Retrieve a specific partner
   * @throws ApiError
   */
  public static partnersServiceGetPartner(
    id: number,
  ): CancelablePromise<Partner> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/partners/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns Partner Update a specific partner
   * @throws ApiError
   */
  public static partnersServiceUpdatePartner(
    id: number,
    requestBody: Partner,
  ): CancelablePromise<Partner> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/partners/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }
}
