/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Assignment } from '../models/Assignment';
import type { AsWorkedUpdate } from '../models/AsWorkedUpdate';
import type { NewAssignment } from '../models/NewAssignment';
import type { PartnerSchedule } from '../models/PartnerSchedule';
import type { PublishDate } from '../models/PublishDate';
import type { Schedule } from '../models/Schedule';
import type { UnpublishedAssignment } from '../models/UnpublishedAssignment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ScheduleService {
  /**
   * @param fromDate
   * @param toDate
   * @param includeUnpublished
   * @returns Schedule Schedule for all partners
   * @throws ApiError
   */
  public static scheduleServiceGetSchedule(
    fromDate: string,
    toDate: string,
    includeUnpublished: boolean,
  ): CancelablePromise<Schedule> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule',
      query: {
        'fromDate': fromDate,
        'toDate': toDate,
        'includeUnpublished': includeUnpublished,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param fromDate
   * @param toDate
   * @returns Schedule Limited schedule for all partners
   * @throws ApiError
   */
  public static scheduleServiceGetLimitedSchedule(
    fromDate: string,
    toDate: string,
  ): CancelablePromise<Schedule> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/limited',
      query: {
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * @param partnerId
   * @param fromDate
   * @param toDate
   * @returns PartnerSchedule Retrieve a specific partner's schedule
   * @throws ApiError
   */
  public static scheduleServiceGetPartnerSchedule(
    partnerId: number,
    fromDate: string,
    toDate: string,
  ): CancelablePromise<PartnerSchedule> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/partner/{partnerId}',
      path: {
        'partnerId': partnerId,
      },
      query: {
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param partner
   * @param token
   * @param fromDate
   * @param toDate
   * @returns binary Download a partner schedule
   * @throws ApiError
   */
  public static scheduleServiceDownloadPartnerSchedule(
    partner: string,
    token: string,
    fromDate?: string,
    toDate?: string,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/partner/{partner}/ical',
      path: {
        'partner': partner,
      },
      query: {
        'token': token,
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not found`,
      },
    });
  }
  /**
   * @param fromDate
   * @param toDate
   * @returns Assignment Ok
   * @throws ApiError
   */
  public static scheduleServiceCreateSchedule(
    fromDate: string,
    toDate: string,
  ): CancelablePromise<Array<Assignment>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/create',
      query: {
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns Assignment Ok
   * @throws ApiError
   */
  public static scheduleServiceCreateAssignment(
    requestBody: NewAssignment,
  ): CancelablePromise<Assignment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/create/assignment',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns Assignment Update unpublished schedule
   * @throws ApiError
   */
  public static scheduleServiceUpdateUnpublishedSchedule(
    requestBody: Array<UnpublishedAssignment>,
  ): CancelablePromise<Array<Assignment>> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/schedule/unpublished',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param toDate
   * @returns string Ok
   * @throws ApiError
   */
  public static scheduleServicePublishSchedule(
    toDate: string,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/publish',
      query: {
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param year
   * @param month
   * @returns PublishDate Retrieve previous publish dates
   * @throws ApiError
   */
  public static scheduleServiceGetPublishDate(
    year: number,
    month: number,
  ): CancelablePromise<PublishDate> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/publish/date',
      query: {
        'year': year,
        'month': month,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @param requestBody
   * @returns Assignment Update schedule as worked
   * @throws ApiError
   */
  public static scheduleServiceUpdateScheduleAsWorked(
    requestBody: AsWorkedUpdate,
  ): CancelablePromise<Array<Assignment>> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/schedule/updateAsWorked',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }
}
